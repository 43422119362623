import { ApiService } from '@tager/web-core';

import { getWebsiteSource } from '@/utils/common';
import { getUtmFromCookie } from '@/utils/cookies';

import {
  HisunCarsLeadFormParams,
  HisunCreditFormParams,
  HisunServiceFormParams,
  JacCarsLeadFormParams,
  JacCreditFormParams,
  JacServiceFormParams,
  JacTestDriveFormParams,
  JetourCarsLeadFormParams,
  B2bCreditFormParams,
  B2bServiceFormParams,
  HelpOnRoadCallRequestParams,
  DetailedCarOrderValues,
  DetailedCarTestDriveParams,
  DetailedCarConsultationParams,
  AmpLandingConsultationParams,
  FormCallbackParams,
  CarSubscriptionParams,
  CarAdvParams,
  CarCaraccidenthelpParams,
  CarModelParams,
  CarModelTestDriveParams,
  CarModelComplectationsParams,
  CarPurchaseCallbackParams,
  ServiceRequestParams,
  ServiceConsultationParams,
  ElectrocarsLandingSelectionCarParams,
  ElectrocarsLandingTestDriveParams,
  ElectrocarsLandingChargersParams,
  ElectrocarsLandingConsultationParams,
  PartnersCarsRequestParams,
  PartnersCarsTestDriveParams,
  TopLevelFunnelLandingConsultationParams,
  TopLevelFunnelLandingCreditParams,
  TopLevelFunnelLandingSelectionCarParams,
  CabinetServiceParams,
  CabinetRequestPartsParams,
  CabinetCarValuationParams,
  SpecialOfferParams,
  CarHelperParams,
  ServiceKoParams,
  CarCatalogParams,
  CreditProductParams,
  CreditAlfabankProductParams,
  RateCarOfflineParams,
  ContactsRequestParams,
  CreditLeasingRequestParams,
  TheLineupCarParams,
  TheLineupTestDriveParams,
  VedaemLandingParams,
  AutoSelectionLandingParams,
  ServiceBenefitsParams,
  ServiceValuationLandingRequestParams,
  ServiceValuationLandingConsultationParams,
  FinanceLandingCalculationParams,
  FinanceLandingProgramParams,
  SollersCarsLeadFormParams,
  SollersCreditFormParams,
  DetailedCarTestDriveOfflineParams,
  CarCheckRequestParams,
  ServiceRequestBodyRepairParams,
} from './typings';

const request = new ApiService({
  baseUrl: {
    csr: process.env.NEXT_PUBLIC_LEADS_SERVICE_URL,
    ssr: process.env.NEXT_PUBLIC_LEADS_SERVICE_URL,
  },
}).getRequest();

/** Hisun **/
export function submitHisunCarsLeadForm(params: HisunCarsLeadFormParams) {
  return request.post({
    path: '/atlantm/hisun/cars-lead',
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

export function submitHisunCreditForm(params: HisunCreditFormParams) {
  return request.post({
    path: '/atlantm/hisun/credit',
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

export function submitHisunServiceForm(params: HisunServiceFormParams) {
  return request.post({
    path: '/atlantm/hisun/service',
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

/** JAC **/
export function submitJacCarsLeadForm(params: JacCarsLeadFormParams) {
  return request.post({
    path: '/atlantm/jac/cars-lead',
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

export function submitJacCreditForm(params: JacCreditFormParams) {
  return request.post({
    path: '/atlantm/jac/credit',
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

export function submitJacServiceForm(params: JacServiceFormParams) {
  return request.post({
    path: '/atlantm/jac/service',
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

export function submitJacTestDriveForm(params: JacTestDriveFormParams) {
  return request.post({
    path: '/atlantm/jac/test-drive',
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

/** Jetour **/
export function submitJetourCarsLeadForm(params: JetourCarsLeadFormParams) {
  return request.post({
    path: '/atlantm/jetour/cars-lead',
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

/** Sollers **/
export function submitSollersCarsLeadForm(params: SollersCarsLeadFormParams) {
  return request.post({
    path: '/atlantm/sollers/car',
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

export function submitSollersCreditForm(params: SollersCreditFormParams) {
  return request.post({
    path: '/atlantm/sollers/credit',
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

/** B2b **/
export function submitB2bCreditForm(params: B2bCreditFormParams) {
  return request.post({
    path: '/atlantm/b2b/credit',
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

export function submitB2bServiceForm(params: B2bServiceFormParams) {
  return request.post({
    path: '/atlantm/b2b/service',
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

/** Car check **/
export function submitCarCheck(
  params: CarCheckRequestParams
): Promise<boolean> {
  return request.post({
    path: `/atlantm/car-check`,
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

/** Help on road **/
export function helpOnRoadCall(
  params: HelpOnRoadCallRequestParams
): Promise<boolean> {
  return request.post({
    path: `/atlantm/help-on-road/call`,
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

/** Detailed car **/
export function submitDetailedCarOrder(params: DetailedCarOrderValues) {
  return request.post({
    path: `/atlantm/cars/request`,
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

export function submitDetailedCarTestDrive(params: DetailedCarTestDriveParams) {
  return request.post({
    path: `/atlantm/cars/test-drive`,
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

export function submitDetailedCarConsultation(
  params: DetailedCarConsultationParams
) {
  return request.post({
    path: `/atlantm/cars/consultation`,
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

export function submitPartnersCarsRequest(params: PartnersCarsRequestParams) {
  return request.post({
    path: `/atlantm/cars/partner-cars-request`,
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

export function submitPartnersCarsTestDrive(
  params: PartnersCarsTestDriveParams
) {
  return request.post({
    path: `/atlantm/cars/partner-cars-test-drive`,
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

export function submitDetailedCarTestDriveOffline(
  params: DetailedCarTestDriveOfflineParams
) {
  return request.post({
    path: `/atlantm/cars/test-drive-request`,
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

export function submitCreditProduct(params: CreditProductParams) {
  return request.post({
    path: `/atlantm/cars/credit-product-request`,
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

export function submitCreditAlfabankProduct(
  params: CreditAlfabankProductParams
) {
  return request.post({
    path: `/atlantm/cars/credit-product-alfabank-request`,
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

/** AMP Landing **/
export function submitAmpLandingConsultation(
  params: AmpLandingConsultationParams
) {
  return request.post({
    path: `/atlantm/amp-landing`,
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

/** Call request **/
export function submitFormCallback(params: FormCallbackParams) {
  return request.post({
    path: `/atlantm/request-call`,
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

/** Car subscription **/
export function submitCarSubscription(params: CarSubscriptionParams) {
  return request.post({
    path: `/atlantm/car-subscription`,
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

/** Car adv **/
export function submitCarAdv(params: CarAdvParams) {
  return request.post({
    path: `/atlantm/car-adv`,
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

/** Car caraccidenthelp **/
export function submitCarCaraccidenthelp(params: CarCaraccidenthelpParams) {
  return request.post({
    path: `/atlantm/car-caraccidenthelp`,
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

/** Car purchase **/
export function submitCarPurchaseCallback(params: CarPurchaseCallbackParams) {
  return request.post({
    path: `/atlantm/car-purchase`,
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

/** Service **/
export function submitServiceRequest(params: ServiceRequestParams) {
  return request.post({
    path: `/atlantm/service/request`,
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

export function submitServiceRequestBodyRepair(
  params: ServiceRequestBodyRepairParams
) {
  return request.post({
    path: `/atlantm/service/request-body-repair`,
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

export function submitServiceConsultation(params: ServiceConsultationParams) {
  return request.post({
    path: `/atlantm/service/consultation`,
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

/** Car model **/
export function submitCarModel(params: CarModelParams) {
  return request.post({
    path: `/atlantm/car-model`,
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

export function submitCarModelTestDrive(params: CarModelTestDriveParams) {
  return request.post({
    path: `/atlantm/car-model/test-drive`,
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

export function submitCarModelComplectations(
  params: CarModelComplectationsParams
) {
  return request.post({
    path: `/atlantm/car-model/complectations`,
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

/** Electrocars landing **/
export function submitElectrocarsLandingSelectionCar(
  params: ElectrocarsLandingSelectionCarParams
) {
  return request.post({
    path: `/atlantm/electrocars-landing/selection-car`,
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

export function submitElectrocarsLandingTestDrive(
  params: ElectrocarsLandingTestDriveParams
) {
  return request.post({
    path: `/atlantm/electrocars-landing/test-drive`,
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

export function submitElectrocarsLandingChargers(
  params: ElectrocarsLandingChargersParams
) {
  return request.post({
    path: `/atlantm/electrocars-landing/chargers`,
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

export function submitElectrocarsLandingConsultation(
  params: ElectrocarsLandingConsultationParams
) {
  return request.post({
    path: `/atlantm/electrocars-landing/consultation`,
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

/** Top level funnel landing **/
export function submitTopLevelFunnelLandingConsultation(
  params: TopLevelFunnelLandingConsultationParams
) {
  return request.post({
    path: `/atlantm/top-level-funnel-landing/consultation`,
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

export function submitTopLevelFunnelLandingCredit(
  params: TopLevelFunnelLandingCreditParams
) {
  return request.post({
    path: `/atlantm/top-level-funnel-landing/credit`,
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

export function submitTopLevelFunnelLandingSelectionCar(
  params: TopLevelFunnelLandingSelectionCarParams
) {
  return request.post({
    path: `/atlantm/top-level-funnel-landing/selection-car`,
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

/** Cabinet **/
export function submitCabinetService(params: CabinetServiceParams) {
  return request.post({
    path: `/atlantm/cabinet/service`,
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

export function submitCabinetRequestParts(params: CabinetRequestPartsParams) {
  return request.post({
    path: `/atlantm/cabinet/request-parts`,
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

export function submitCabinetCarValuation(params: CabinetCarValuationParams) {
  return request.post({
    path: `/atlantm/cabinet/car-valuation`,
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

/** Special offer **/
export function submitSpecialOffer(params: SpecialOfferParams) {
  return request.post({
    path: `/atlantm/special-offer`,
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

/** Car helper **/
export function submitCarHelper(params: CarHelperParams) {
  return request.post({
    path: `/atlantm/car-helper`,
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

/** Service KO **/
export function submitServiceKo(params: ServiceKoParams) {
  return request.post({
    path: `/atlantm/service-ko`,
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

/** Car catalog **/
export function submitCarCatalog(params: CarCatalogParams) {
  return request.post({
    path: `/atlantm/car-catalog`,
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

/** Rate car **/
export function submitRateCarOffline(params: RateCarOfflineParams) {
  return request.post({
    path: `/atlantm/rate-car/offline`,
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

/** Contacts **/
export function submitContactsRequest(params: ContactsRequestParams) {
  return request.post({
    path: `/atlantm/contacts`,
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

/** Credit Leasing **/
export function submitCreditLeasingRequest(params: CreditLeasingRequestParams) {
  return request.post({
    path: `/atlantm/credit-leasing`,
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

/** The Lineup **/
export function submitTheLineupCar(params: TheLineupCarParams) {
  return request.post({
    path: `/atlantm/the-lineup/car`,
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

export function submitTheLineupTestDrive(params: TheLineupTestDriveParams) {
  return request.post({
    path: `/atlantm/the-lineup/test-drive`,
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

/** Vedaem Landing **/
export function submitVedaemLanding(params: VedaemLandingParams) {
  return request.post({
    path: `/atlantm/vedaem-landing`,
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

/** Auto Selection Landing **/
export function submitAutoSelectionLanding(params: AutoSelectionLandingParams) {
  return request.post({
    path: `/atlantm/auto-selection-landing`,
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

/** Service Benefits **/
export function submitServiceBenefits(params: ServiceBenefitsParams) {
  return request.post({
    path: `/atlantm/service-benefits`,
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

/** Service Valuation Landing **/
export function submitServiceValuationLandingRequest(
  params: ServiceValuationLandingRequestParams
) {
  return request.post({
    path: `/atlantm/service-valuation-landing/request`,
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

export function submitServiceValuationLandingConsultation(
  params: ServiceValuationLandingConsultationParams
) {
  return request.post({
    path: `/atlantm/service-valuation-landing/consultation`,
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

/** Finance Landing **/
export function submitFinanceLandingCalculation(
  params: FinanceLandingCalculationParams
) {
  return request.post({
    path: `/atlantm/finance-landing/calculation`,
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}

export function submitFinanceLandingProgram(
  params: FinanceLandingProgramParams
) {
  return request.post({
    path: `/atlantm/finance-landing/program`,
    body: { ...params, source: getWebsiteSource(), utm: getUtmFromCookie() },
  });
}
